/*
 * Translated default messages for the jQuery validation plugin.
 * Locale: SR (Serbian - Latin alphabet; srpski jezik - latinica)
 */


(function($) {
	$.extend($.validator.messages, {
		required: "Polje je obavezno.",
		remote: "Sredite ovo polje.",
		email: "Unesi ispravnu e-mail adresu",
		url: "Unesi ispravan URL.",
		date: "Unesi ispravan datum.",
		dateISO: "Unesi ispravan datum (ISO).",
		number: "Unesi ispravan broj.",
		digits: "Unesi samo cifre.",
		creditcard: "Unesi ispravan broj kreditne kartice.",
		equalTo: "Unesi istu vrednost ponovo.",
		extension: "Unesi vrednost sa odgovarajućom ekstenzijom.",
		maxlength: $.validator.format("Unesi manje od {0} karaktera."),
		minlength: $.validator.format("Unesi barem {0} karaktera."),
		rangelength: $.validator.format("Unesi vrednost dugačku između {0} i {1} karaktera."),
		range: $.validator.format("Unesi vrednost između {0} i {1}."),
		max: $.validator.format("Unesi vrednost manju ili jednaku {0}."),
		min: $.validator.format("Unesi vrednost veću ili jednaku {0}.")
	});
}(jQuery));
